<template>
	<v-app>
		<v-app-bar app color="primary" class="main-toolbar">
			<img class="app-logo" alt="PLIC Admin" :src="logoUrl">
			<v-toolbar-title class="headline white--text hidden-sm-and-down">{{ toolbarName }}</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-toolbar-items>
				<v-btn v-for="item in toolbarItems" :key="item.name" :to="item.to" color="secondary">
					<span>{{ item.text }}</span>
				</v-btn>
			</v-toolbar-items>
		</v-app-bar>

		<v-main class="primary">
			<v-container class="app-content" fluid fill-height>
				<transition name="fade" v-if="!loading">
					<router-view :studio-org="studioOrg" :app-name="appName"></router-view>
				</transition>

				<v-layout justify-center align-center v-if="loading">
					<v-progress-circular indeterminate></v-progress-circular>
				</v-layout>
			</v-container>
		</v-main>

		<update-notification />
	</v-app>
</template>

<script>
import UpdateNotification from '../PlicCanvas/vue/common/UpdateNotification';

export default {
	name: 'App',
	data() {
		return {
			loading: false,
			studioOrg: null
		};
	},
	computed: {
		appName() {
			if(this.studioOrg) {
				return this.studioOrg.title;
			} else {
				return 'PLIC Admin';
			}
		},
		routeDisplay() {
			switch(this.$route.name) {
				case 'login':
					return 'Login';
			}

			return null;
		},
		toolbarName() {
			if(this.routeDisplay) {
				return this.routeDisplay;
			} else {
				return this.appName;
			}
		},
		toolbarItems() {
			let items = [];
			
			if(this.$router.match('register') && this.$route.name === 'login' && this.studioOrg) {
				items.push({
					text: 'Register',
					to: {
						name: 'register'
					}
				});
			}
			if(this.$route.name !== 'login') {
				items.push({
					text: 'Login',
					to: {
						name: 'login'
					}
				});
			}

			return items;
		}
	},
	watch: {
		routeDisplay() {
			this.updateTitle();
		}
	},
	methods: {
		updateTitle() {
			if(this.routeDisplay) {
				document.title = this.appName + ' - ' + this.routeDisplay;
			} else {
				document.title = this.appName;
			}
		}
	},
	mounted() {
		this.updateTitle();
	},
	components: {
		UpdateNotification
	}
}
</script>

<style>
	html {
		overflow: auto !important;
	}
	html, body, .v-application {
		height: 100%;
		height: 100vh;
		height: -webkit-fill-available;
	}

	.v-application > .v-application--wrap {
		min-height: 100vh !important;
		min-height: -webkit-fill-available !important;
	}

	.v-application .main-toolbar .app-logo {
		max-height: 90%;
		margin-right: 1em;
	}

	.v-application .app-content {
		text-align: center;
	}

	.app-content > .layout:nth-child(2) {
		display: none;
	}
	.fade-enter-active, .fade-leave-active {
		transition-property: opacity;
		transition-duration: 0.3s;
	}

	.fade-enter-active {
		transition-delay: 0.3s;
	}

	.fade-enter, .fade-leave-active {
		opacity: 0
	}
</style>