import Vue from 'vue';
import Router from 'vue-router';
import LoginPage from '../PlicCanvas/vue/login/LoginPage';

Vue.use(Router);

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/guest/login',
			name: 'login',
			component: LoginPage,
			alias: ['/login']
		}
	]
})
