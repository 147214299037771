import '../../app/PlicCanvas/vue/utils/polyfills.js'
import Vue from 'vue'
import App from './App.vue'
import vuetify from '../js/plugins/vuetify';
import '../js/plugins/jquery';
import router from './router';
import Vuelidate from 'vuelidate';
import '../js/mixins/index';
import '../PlicCanvas/vue/utils/registerServiceWorker';
import '../PlicCanvas/vue/utils/external-scripts';
import '../css/force-common-chunk.css';
require('../PlicCanvas/vue/common/locales');

require('../PlicCanvas/js/utils.js');

Vue.config.productionTip = false;
Vue.use(Vuelidate);

new Vue({
  render: h => h(App),
  vuetify,
  router
}).$mount('#app')
