<template>
	<v-snackbar v-model="snackbar" :timeout="timeout">
		New version available!

		<template v-slot:action="{ attrs }">
			<v-btn color="primary" text v-bind="attrs" @click="refreshApp">
				Refresh
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
export default {
	data() {
		return {
			snackbar: false,
			timeout: 10000
		};
	},
	methods: {
		refreshApp() {
			// Prevent double clicking
			if(!this.snackbar) {
				return;
			}

			this.snackbar = false;
			window.location.reload();
		}
	},
	created() {
		// Refresh all open app tabs when a new service worker is installed.
		if(navigator && navigator.serviceWorker) {
			navigator.serviceWorker.addEventListener('controllerchange', () => {
				this.snackbar = true;
			});
		}
	}
};
</script>